import SuusDefaultValues from './SuusDefaultValues';

export default class Suus {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.clientId = data ? data.clientId : '';
    this.configurationName = data ? data.configurationName : '';
    this.password = data ? data.password : '';
    this.metadata = data ? data.metadata : [];
    this.courier = data ? data.courier : '';
    this.login = data ? data.login : '';
    this.urlAddress = data ? data.urlAddress : '';
    this.suusDefaultValues = new SuusDefaultValues();
  }
}
