<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.name').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="symbol"
              :title="$t('courierSet.prcelSymbol')"
              rules="required"
              :filed-items="packageTypes"
              @input="update"
            />
          </v-col>
          <v-col>
            <text-field
              v-model="quantity"
              :title="$t('courierSet.quantity')"
              rules="required|numeric"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="0"
      class="mt-4"
    >
      <v-card-title class="pb-0">
        {{ $t('courierSet.cargo').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="returnable"
              :title="$t('courierSet.returnable')"
              rules="numeric"
              @input="update"
            />
          </v-col>
          <v-col>
            <check-field
              v-model="stackable"
              :label="$t('courierSet.stackable')"
              :messages="$t('courierSet.stackableHint')"
              height="106px"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';
import CheckField from '@/components/inputs/CheckField.vue';

export default {
  components: {
    TextField,
    SelectField,
    CheckField,
  },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    confSet: null,
    id: null,
    tenantId: null,
    symbol: null,
    quantity: null,
    weightKg: null,
    lengthCm: null,
    widthCm: null,
    heightCm: null,
    returnable: null,
    stackable: false,
    packageTypes: [
      { name: 'beczka', description: 'BEC – beczka', code: 'BEC' },
      { name: 'bela', description: 'BEL – bela', code: 'BEL' },
      { name: 'big bag', description: 'BIG – big bag', code: 'BIG' },
      {
        name: 'paleta chep 1/2',
        description: 'CH1 – paleta chep 1/2',
        code: 'CH1',
      },
      {
        name: 'paleta chep 1/4',
        description: 'CH2 – paleta chep 1/4',
        code: 'CH2',
      },
      { name: 'paleta chep', description: 'CHP– paleta chep', code: 'CHP' },
      { name: 'colli', description: 'COL – colli', code: 'COL' },
      { name: 'paleta DHP', description: 'DHP – paleta DHP', code: 'DHP' },
      {
        name: 'kontenerek DOK',
        description: 'DOK – kontenerek DOK',
        code: 'DOK',
      },
      {
        name: 'pojemnik DPPL',
        description: 'DPL – pojemnik DPPL',
        code: 'DPL',
      },
      { name: 'pal. EUR', description: 'EUR – pal. EUR', code: 'EUR' },
      { name: 'hobok', description: 'HB – hobok', code: 'HB' },
      {
        name: 'paleta jednorazowa',
        description: 'JED – paleta jednorazowa',
        code: 'JED',
      },
      { name: 'karton', description: 'KAR – karton', code: 'KAR' },
      { name: 'towar luzem', description: 'LUZ – towar luzem', code: 'LUZ' },
      { name: 'paleta inna', description: 'PAL – paleta inna', code: 'PAL' },
      {
        name: 'paleta przem.',
        description: 'PLT – paleta przem.',
        code: 'PLT',
      },
      { name: 'rolka', description: 'ROL – rolka', code: 'ROL' },
      {
        name: 'ciężka skrzynia',
        description: 'SKC – ciężka skrzynia',
        code: 'SKC',
      },
      { name: 'skrzynia', description: 'SKR – skrzynia', code: 'SKR' },
      {
        name: 'wózek kwiatowy',
        description: 'WCC – wózek kwiatowy',
        code: 'WCC',
      },
      { name: 'wiązka', description: 'WIA – wiązka', code: 'WIA' },
      { name: 'worek', description: 'WOR – worek', code: 'WOR' },
    ],
  }),

  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.transportUnit = {
        parameters: {
          id: this.id,
          tenantId: this.tenantId,
          clientEntityState: this.isNew ? 4 : 3,
          symbol: this.symbol,
          quantity: this.quantity ? this.quantity : 0,
          weightKg: this.weightKg ? this.weightKg : 0,
          lengthCm: this.lengthCm ? this.lengthCm : 0,
          widthCm: this.widthCm ? this.widthCm : 0,
          heightCm: this.heightCm ? this.heightCm : 0,
          returnable: this.returnable ? this.returnable : 0,
          stackable: !!this.stackable,
        },
      };
      this.$emit('input', this.transportUnit);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.package = this.confSet?.suusDefaultValues?.package;
      this.tenantId = this.package?.tenantId ? this.package?.tenantId : null;
      this.id = this.package?.id ? this.package?.id : null;
      this.symbol = this.package?.symbol ? this.package?.symbol : null;
      this.quantity = this.package?.quantity ? this.package?.quantity : null;
      this.weightKg = this.package?.weightKg ? this.package?.weightKg : null;
      this.lengthCm = this.package?.lengthCm ? this.package?.lengthCm : null;
      this.widthCm = this.package?.widthCm ? this.package?.widthCm : null;
      this.heightCm = this.package?.heightCm ? this.package?.heightCm : null;
      this.returnable = this.package?.returnable ? this.package?.returnable : null;
      this.stackable = this.package?.stackable ? this.package?.stackable : false;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select {
  padding: 0px 10px 0px 10px;
}
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
