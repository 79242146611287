export default class Header {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.reference = data ? data.reference : '';
    this.loadingDate = data ? data.loadingDate : '';
    this.unloadingDate = data ? data.unloadingDate : '';
    this.descriptionOfGoods = data ? data.descriptionOfGoods : '';
    this.remarks = data ? data.remarks : '';
    this.incoterms = data ? data.incoterms : '';
    this.costGroup = data ? data.costGroup : '';
    this.freight = data ? data.freight : '';
    this.currency = data ? data.currency : '';
    this.category = data ? data.category : '';
    this.orderType = data ? data.orderType : '';
  }
}
