<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.ADR').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="unCode"
              :title="$t('courierSet.unCode')"
              @input="update"
            />
          </v-col>
          <v-col>
            <text-field
              v-model="adrTechnicalName"
              :title="$t('courierSet.adrTechnicalName')"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="adrAmount"
              :title="$t('courierSet.adrAmount')"
              rules="double:2"
              @input="update"
            />
          </v-col>
          <v-col>
            <select-field
              v-model="adrUnit"
              :title="$t('courierSet.adrUnit')"
              :filed-items="adrUnits"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="packageQuantity"
              :title="$t('courierSet.packageQuantity')"
              rules="numeric"
              @input="update"
            />
          </v-col>
          <v-col>
            <select-field
              v-model="packageCode"
              :title="$t('courierSet.packageCode')"
              :filed-items="packageCodes"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <select-field
              v-model="packageGroup"
              :title="$t('courierSet.packageGroup')"
              :filed-items="packageGroups"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';

export default {
  components: {
    TextField,
    SelectField,
  },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },

  data: () => ({
    confSet: null,
    adrService: null,
    id: null,
    tenantId: null,
    packageQuantity: null,
    adrAmount: null,
    packageGroup: null,
    unCode: null,
    packageCode: null,
    adrUnit: null,
    adrTechnicalName: null,
    packageGroups: [
      { id: 0, name: 'I' },
      { id: 1, name: 'II' },
      { id: 2, name: 'III' },
    ],
    packageCodes: [
      {
        id: 'BEC',
        name: 'BEC - Bęben',
      },
      {
        id: 'BUT',
        name: 'BUT - Butla',
      },
      {
        id: 'DOP',
        name: 'DOP - Duże opakowanie',
      },
      {
        id: 'DPPL',
        name: 'DPPL',
      },
      {
        id: 'KAN',
        name: 'Kanister',
      },
      {
        id: 'OML',
        name: 'OML - opakowanie metalowe lekkie',
      },
      {
        id: 'SKR',
        name: 'SKR - Skrzynia',
      },
      {
        id: 'WOR',
        name: 'WOR - Worek',
      },
      {
        id: 'ROL',
        name: 'ROL - Rolka',
      },
    ],
    adrUnits: [
      { id: 'KGN', name: 'KGN - kg netto' },
      { id: 'KGB', name: 'KGB - kg brutto' },
      { id: 'L', name: 'L - litry' },
    ],
  }),
  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.adrService = {
        parameters: {
          clientEntityState: this.isNew ? 4 : 3,
          id: this.id,
          tenantId: this.tenantId,
          packageQuantity: this.packageQuantity ? this.packageQuantity : 0,
          adrAmount: this.adrAmount ? this.adrAmount : 0,
          packageGroup: this.packageGroup,
          unCode: this.unCode,
          packageCode: this.packageCode,
          adrUnit: this.adrUnit,
          adrTechnicalName: this.adrTechnicalName,
        },
      };
      this.$emit('input', this.adrService);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.adrService = this.confSet?.suusDefaultValues?.adrService;
      this.tenantId = this.adrService?.tenantId ? this.adrService?.tenantId : null;
      this.id = this.adrService?.id ? this.adrService?.id : null;
      this.packageQuantity = this.adrService?.packageQuantity
        ? this.adrService?.packageQuantity
        : null;
      this.adrAmount = this.adrService?.adrAmount ? this.adrService?.adrAmount : null;
      this.packageGroup = this.adrService?.packageGroup ? this.adrService?.packageGroup : null;
      this.unCode = this.adrService?.unCode ? this.adrService?.unCode : null;
      this.packageCode = this.adrService?.packageCode ? this.adrService?.packageCode : null;
      this.adrUnit = this.adrService?.adrUnit ? this.adrService?.adrUnit : null;
      this.adrTechnicalName = this.adrService?.adrTechnicalName
        ? this.adrService?.adrTechnicalName
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select {
  padding: 0px 10px 0px 10px;
}
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
