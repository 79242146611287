export default class AdrService {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.packageQuantity = data ? data.packageQuantity : '';
    this.adrAmount = data ? data.adrAmount : '';
    this.packageGroup = data ? data.packageGroup : '';
    this.unCode = data ? data.unCode : '';
    this.packageCode = data ? data.packageCode : '';
    this.adrUnit = data ? data.adrUnit : '';
    this.adrTechnicalName = data ? data.adrTechnicalName : '';
  }
}
