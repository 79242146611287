export default class Package {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.symbol = data ? data.symbol : '';
    this.quantity = data ? data.quantity : '';
    this.weightKg = data ? data.weightKg : '';
    this.lengthCm = data ? data.lengthCm : '';
    this.widthCm = data ? data.widthCm : '';
    this.heightCm = data ? data.heightCm : '';
    this.returnable = data ? data.returnable : '';
    this.stackable = data ? data.stackable : false;
  }
}
