<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.serviceInfo').toUpperCase() }}
      </v-card-title>

      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="reference"
              :title="$t('courierSet.referenceNumber')"
              @input="update"
            />
          </v-col>

          <v-col>
            <text-field
              v-model="descriptionOfGoods"
              :title="$t('courierSet.descriptionOfGoods')"
              rules="required"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="remarks"
              :title="$t('courierSet.remarks')"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              v-model="menuLoadingDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="loadingDate"
                  :label="$t('courierSet.loadingDate')"
                  readonly
                  dense
                  outlined
                  persistent-placeholder
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="loadingDate"
                @input="
                  menuLoadingDate = false;
                  update();
                "
              />
            </v-menu>
          </v-col>

          <v-col>
            <v-menu
              v-model="menuUnloadingDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="unloadingDate"
                  :label="$t('courierSet.unloadingDate')"
                  readonly
                  dense
                  outlined
                  persistent-placeholder
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="unloadingDate"
                @input="
                  menuUnloadingDate = false;
                  update();
                "
              />
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <select-field
              v-model="incoterms"
              :title="$t('courierSet.incoterms')"
              :filed-items="listOfIncoterms"
              @input="update"
            />
          </v-col>
          <v-col>
            <select-field
              v-model="category"
              :title="$t('courierSet.category')"
              :filed-items="categories"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="orderType"
              :title="$t('courierSet.orderType')"
              :hint="orderTypeHint"
              :filed-items="orderTypes"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';

export default {
  components: {
    SelectField,
    TextField,
  },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },

  data: () => ({
    confSet: null,
    sendingMethod: null,
    id: null,
    tenantId: null,
    reference: null,
    loadingDate: null,
    unloadingDate: null,
    descriptionOfGoods: null,
    remarks: null,
    incoterms: null,
    costGroup: null,
    category: null,
    orderType: null,
    menuLoadingDate: null,
    menuUnloadingDate: null,
    listOfIncoterms: [
      { id: 0, name: 'EXW' },
      { id: 1, name: 'FCA' },
      { id: 2, name: 'FAS' },
      { id: 3, name: 'FOB' },
      { id: 4, name: 'CFR' },
      { id: 5, name: 'CIF' },
      { id: 6, name: 'CPT' },
      { id: 7, name: 'CIP' },
      { id: 8, name: 'DAP' },
      { id: 9, name: 'DDP' },
    ],
    currencies: [
      {
        id: 0,
        name: 'PLN',
      },
      {
        id: 1,
        name: 'EUR',
      },
      {
        id: 2,
        name: 'USD',
      },
      {
        id: 3,
        name: 'CHF',
      },
      {
        id: 4,
        name: 'SEK',
      },
      {
        id: 5,
        name: 'NOK',
      },
      {
        id: 6,
        name: 'CZK',
      },
      {
        id: 7,
        name: 'RON',
      },
      {
        id: 8,
        name: 'HUF',
      },
      {
        id: 9,
        name: 'HRK',
      },
      {
        id: 10,
        name: 'BGN',
      },
      {
        id: 11,
        name: 'DKK',
      },
      {
        id: 12,
        name: 'GBP',
      },
      {
        id: 13,
        name: 'RSD',
      },
      {
        id: 14,
        name: 'RUB',
      },
      {
        id: 15,
        name: 'TRY',
      },
      {
        id: 16,
        name: 'INNA',
      },
    ],
    orderTypes: [
      { id: 1, name: 'B2C' },
      { id: 2, name: 'B2B' },
    ],
    categories: [
      {
        id: 'DROBNICA',
        name: 'Standard Drobnica',
      },
      {
        id: '24PLUS',
        name: 'System 24 Plus',
      },
    ],

    orderTypeHint: `Typ zlecenia: B2B lub B2C, w zależności od poziomu usługi. 
    Dla użytkownika z typem konta Multichannel obie wartości poprawne, jedna wymagana. 
    Dla zleceń międzynarodowych obsługiwany jest wyłącznie typ B2B.`,
  }),
  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.serviceParameters = {
        parameters: {
          clientEntityState: this.isNew ? 4 : 3,
          tenantId: this.tenantId,
          id: this.id,
          reference: this.reference,
          loadingDate: this.loadingDate,
          unloadingDate: this.unloadingDate,
          descriptionOfGoods: this.descriptionOfGoods,
          remarks: this.remarks,
          incoterms: this.incoterms,
          costGroup: this.costGroup,
          freight: this.freight,
          currency: this.currency,
          category: this.category,
          orderType: this.orderType,
        },
      };
      this.$emit('input', this.serviceParameters);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.header = this.confSet?.suusDefaultValues?.header;
      this.id = this.header?.id ? this.header?.id : null;
      this.tenantId = this.header?.tenantId ? this.header?.tenantId : null;

      this.reference = this.header?.reference ? this.header?.reference : null;
      this.loadingDate = this.header?.loadingDate ? this.header?.loadingDate : null;
      this.unloadingDate = this.header?.unloadingDate ? this.header?.unloadingDate : null;
      this.descriptionOfGoods = this.header?.descriptionOfGoods
        ? this.header?.descriptionOfGoods
        : null;
      this.remarks = this.header?.remarks ? this.header?.remarks : null;
      this.incoterms = this.header?.incoterms ? this.header?.incoterms : null;
      this.costGroup = this.header?.costGroup ? this.header?.costGroup : null;
      this.category = this.header?.category ? this.header?.category : null;
      this.orderType = this.header?.orderType ? this.header?.orderType : null;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select {
  padding: 0px 10px 0px 10px;
}
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
