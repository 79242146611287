import Header from './Header';
// import Address from './Address';
import Package from './Package';
import AdrService from './AdrService';
import Cod from './Cod';

export default class SuusDefaultValues {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.clientId = data ? data.clientId : '';
    this.header = new Header();
    // this.loadingAddress = new Address();
    // this.unloadingAddress = new Address();
    // this.shipper = new Address();
    // this.consignee = new Address();
    this.package = new Package();
    this.adrService = new AdrService();
    this.cod = new Cod();
  }
}
